import React from 'react';

import HelmetLayout from '../components/layouts/helmet_layout/helmet_layout';
import MainLayout from '../components/layouts/main_layout/main_layout';
import BlogActualityLayout from '../components/layouts/blog_actuality_layout/blog_actuality_layout';

const BlogPost = ({ pageContext }) => (
	<HelmetLayout>
		<MainLayout
			footerProps={{
				dismissedSections: ['create-demo-account', 'trusting-customers-list']
			}}
		>
			<BlogActualityLayout
				type="blog"
				{...{ pageContext }}
			/>
		</MainLayout>
	</HelmetLayout>
);

export default BlogPost;
